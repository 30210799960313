import { Image } from 'vant';
export default {
  components: {
    [Image.name]: Image
  },
  computed: {
    url() {
      const {
        type
      } = this.$route.query;
      if (type == 'tom') return 'http://gp-www-cdn.oss-cn-shenzhen.aliyuncs.com/gupao-edu-college/assets/tom_desc.png';else return 'http://gp-www-cdn.oss-cn-shenzhen.aliyuncs.com/gupao-edu-college/assets/jack_desc.png';
    }
  },
  created() {
    const {
      type
    } = this.$route.query;
    if (type == 'tom') document.title = "公开课";else document.title = "训练营";
  },
  methods: {
    nextClick() {
      const {
        type
      } = this.$route.query;
      if (type == 'tom') window.location.href = "https://ke.gupaoedu.cn/course/detail/1808";else window.location.href = "https://ke.gupaoedu.cn/course/detail/1876";
    }
  }
};